
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumbs/breadcrumb";
import { themeName } from "@/core/helpers/documentation";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "borders",
  components: {
    CodeHighlighter
  },
  setup() {
    const gray = [
      "gray-100",
      "gray-200",
      "gray-300",
      "gray-400",
      "gray-500",
      "gray-600",
      "gray-700",
      "gray-800",
      "gray-900"
    ];

    onMounted(() => {
      setCurrentPageTitle("Borders");
    });

    return {
      themeName,
      gray
    };
  }
});
